/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2019-10-30 19:28:37
 * @Last Modified by: zhangzc
 * @Last Modified time: 2020-03-13 13:25:25
 * @Description: 路由
 * @Route: Route
 */
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'index',
        component: () =>
            import(/* webpackChunkName: "index" */ '@/pages/index'),
        meta: {
            title: 'C端账号系统',
        },
    },
    {
        path: '/client-cancel',
        name: 'client',
        component: () =>
            import(/* webpackChunkName: "index" */ '@/pages/client/index'),
        meta: {
            title: '账号注销协议',
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
});

export default router;
