import Vue from 'vue';
import VConsole from 'vconsole';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import fetch from '@/utils/fetch';
import { Button, Radio } from 'vant';
import router from './router';
import store from './store';
import 'lib-flexible/flexible';
import './assets/styles/base';

if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-new
    new VConsole();
} else {
    Sentry.init({
        dsn: '',
        integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    });
}

Vue.prototype.$fetch = fetch;
Vue.use(Button);
Vue.use(Radio);

new Vue({
    router,
    store,
}).$mount('#app'); // 手动挂载
